$(document).ready(function () {

  $('#nav-novelty-slider').removeClass('d-none');
  $('#novelty-block .loading-page').addClass('d-none');

  function scrollToAnchor(aid){
    var aTag = $("div#"+ aid);
    $('html,body').animate({scrollTop: aTag.offset().top-60},'slow');
  }

  $('.anchor-for-section.nav-novelty').on('click', function(){
    scrollToAnchor('nav-novelty-section');
  });

  $('.anchor-for-section.nav-promotions').on('click', function(){
    scrollToAnchor('nav-promotions-section');
  });

  $('.anchor-for-section.nav-top-sales').on('click', function(){
    scrollToAnchor('nav-top-sales-section');
  });
});
